<template>
  <el-radio-group
    v-model="target"
    style="display: block"
    @change="$emit('update:choosed', target)"
  >
    <el-radio v-for="(v, index) in version" :key="index" :label="index">
      <div>
        <div style="color: #909399">模板文案{{ index + 1 }}：</div>
        <strong style="font-size: 1.1em">{{ v.title }}</strong>
        <div>{{ v.content }}</div>
      </div>
    </el-radio>
  </el-radio-group>
</template>
<script>
export default {
  name: 'PushNotificationBlock',
  props: {
    choosed: { type: Number, default: 0 },
    version: { type: Array },
  },
  data() {
    return {
      target: this.choosed,
    };
  },
};
</script>
<style lang="scss" scoped>
.el-radio {
  display: grid;
  grid-template-columns: 15px 1fr;
  align-items: center;
  margin-bottom: 15px;
  line-height: 1.5;
  width: fit-content;
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
