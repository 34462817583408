/**
 * recover_fee          回收服務費
 * push_fee             推播服務費
 * min_feedback_point   最低問卷回饋點數
 * pro_filter_fee       進階受眾篩選服務費
 */

const getFee = {
  data() {
    return {
      // 企業/個人
      typeOne: {
        recover_fee: 24,
        push_fee: 1,
        min_feedback_point: 30,
        pro_filter_fee: 5,
      },
      // 學術單位/公益團體
      typeTwo: {
        recover_fee: 0,
        push_fee: 0.5,
        min_feedback_point: 10,
        pro_filter_fee: 5,
      },
      userType: {},
    };
  },
  created() {
    const userType = Number(sessionStorage.getItem('userType'));
    if (userType === 3) {
      this.userType = this.typeTwo;
    } else this.userType = this.typeOne;
  },
  computed: {
    recoverFee() {
      return this.userType?.recover_fee;
    },
    pushFee() {
      return this.userType?.push_fee;
    },
    minFeedback() {
      return this.userType?.min_feedback_point;
    },
    proFilterFee() {
      return this.userType?.pro_filter_fee;
    },
  },
};

export default getFee;
