<template>
  <el-dialog
    v-loading="loading"
    title="預覽"
    :visible.sync="dialogVisible"
    width="375px"
    :before-close="handleClose"
    @opened="handleDialogOpened"
    :modal-append-to-body="false"
    :append-to-body="true"
  >
    <!-- 問卷介紹頁 -->
    <QuestionFormalIntro
      v-if="screenType === 'start' || screenType === 'end'"
      :dialog-visible="dialogVisible"
      :close-dialog="handleClose"
      :is-start-fill.sync="is_start_fill"
      :screenType.sync="screenType"
    ></QuestionFormalIntro>
    <!-- 問卷回答頁 -->
    <QuestionFormalScreen
      v-else
      :dialog-visible="dialogVisible"
      :screenType.sync="screenType"
    ></QuestionFormalScreen>
  </el-dialog>
</template>
<script>
import QuestionFormalScreen from '@/components/realFrame/QuestionFormalScreen';
import QuestionFormalIntro from '@/components/realFrame/QuestionFormalIntro.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PreviewQuestions',
  components: { QuestionFormalIntro, QuestionFormalScreen },
  data() {
    return {
      dialogVisible: false,
      is_start_fill: false,
      screenType: 'start',
    };
  },
  mounted() {
    this.dialogVisible = this.$route.query.dialog === 'PreviewQuestions';
  },
  computed: {
    ...mapGetters(['loading']),
  },
  watch: {
    $route() {
      this.dialogVisible = this.$route.query.dialog === 'PreviewQuestions';
    },
  },
  methods: {
    ...mapActions(['setCurrentQuestionIndex']),
    handleClose(done) {
      let query = { ...this.$route.query };
      delete query.dialog;
      this.$router.push({
        path: this.$route.path,
        query,
      });
      this.dialogVisible = false;
      this.is_start_fill = false;
      this.screenType = 'start';
      this.setCurrentQuestionIndex(null);
      // done();
    },
    handleDialogOpened() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #eeebeb;
}
::v-deep .el-dialog__body {
  padding: 0;
  height: 669px;
  overflow: scroll;
}
</style>
