import { mapGetters } from 'vuex';

const getNotificationFormat = {
  data() {
    return {
      version: [],
    };
  },
  computed: {
    ...mapGetters(['getQuestionnaireTitle']),
  },
  created() {
    this.getVersions();
  },
  watch: {
    getQuestionnaireTitle() {
      this.getVersions();
    },
  },
  methods: {
    getVersions() {
      this.version = [
        {
          title: '專屬問卷邀請',
          content: `嗨～想邀請你填寫${this.getQuestionnaireTitle}，雲寶會給認真填答的你專屬的獎勵喔！`,
        },
        {
          title: this.getQuestionnaireTitle,
          content:
            '您專屬的問卷調查獎勵即將發送完畢，趕緊來填寫問卷領取專屬獎勵吧！',
        },
        {
          title: '專屬問卷邀請',
          content: `恭喜您成為雀屏中選之人，誠摯邀請你填寫${this.getQuestionnaireTitle}！`,
        },
      ];
    },
  },
};

export default getNotificationFormat;
